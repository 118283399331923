import React from "react";
import Layout from "../containers/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import Photo from "../components/photo";

export const query = graphql`
  query photoTypeTemplateQuery($id: String, $title: String $title_en: String) {
    category: sanityPhotoCategory(id: { eq: $id }) {
      title
      title_en
    }
    photos: allSanityCategorizedPhotos(
      filter: {categories: {elemMatch: { title: { eq: $title } }}}
    ) {
      nodes {
        categories {
          title
          title_en
        }
        id
        title
        title_en
        desc
        desc_en
        photo {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
          alt
          alt_en
        }
      }
    }
    photos_en: allSanityCategorizedPhotos(
      filter: {categories: {elemMatch: { title_en: { eq: $title_en } }}}
    ) {
      nodes {
        id
        title
        title_en
        desc
        desc_en
        categories {
          title
          title_en
        }
        photo {
          asset {
            gatsbyImageData(
              placeholder: BLURRED
            )
          }
          alt
          alt_en
        }
      }
    }
  }
`;

const photoType = props => { 
  console.log(props)
  const { data, errors } = props;
  const { category, photos, photos_en } = data
  const filteredPhotos = ( photos.length ? photos : photos_en || { nodes: []} ).nodes.filter(p => p.categories[0].title === category.title);
  const passProps = { ...category, photos: { nodes: filteredPhotos} }
  let isEn = false
  if(typeof window !== 'undefined') {
    isEn = window.location.href.includes('/en/')
  }
  
  return (
    <Layout isEn={isEn}>
      {errors && <SEO title="GraphQL Error" />}
      {<SEO title={isEn ? category.title_en : category.title || "Untitled"} />}
      {<Photo {...passProps} isEn={isEn} />}
    </Layout>
  );
};

export default photoType;
